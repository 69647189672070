import React, { useEffect, useContext } from "react";

import Layout from "../components/Layout";
import { Seo } from "../base/Seo";

import SettingsContext from "../context/SettingsContext";

import { FcGoogle } from "react-icons/fc";

import * as styles from "./signup.module.scss";

export default function Signup() {
  const { isSidebarOpen, toggleSidebarOpen } = useContext(SettingsContext);

  /**
   * Close the sidebar when the page loads
   */
  useEffect(() => {
    console.log(isSidebarOpen);
    if (isSidebarOpen) {
      toggleSidebarOpen();
    }
  }, []);

  return (
    <>
      <Seo title="Sign up" description="Sign up Description" />

      <Layout topbarFixed={true}>
        <div className={styles.signupWrapper}>
          <div className="half left-half">
            <div className="section signupPresent">
              <h1>
                An easy way to collect all your favorite free web & design
                resources
              </h1>
              <p>Find, save and organize with ResourceX</p>
            </div>
          </div>

          <div className="half right-half">
            <div className="section formHeader">
              <div className="innerSection">
                <div className="tabs is-centered">
                  <ul>
                    <li className="is-active">
                      <a>Log in</a>
                    </li>
                    <li className="">
                      <a>Create an account</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="section formWrapper">
              <div className="socialLogins">
                <button className="button is-rounded is-fullwidth">
                  <span clasName="icon">
                    <FcGoogle />
                  </span>{" "}
                  Continue with Google
                </button>
              </div>
              <form className="">
                <div className="field">
                  <label className="label" htmlFor="username">
                    Username
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      name="username"
                      placeholder="ex. Elon Tusk"
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label" htmlFor="email">
                    Email
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type="email"
                      name="email"
                      placeholder="ex. elontusk@gmail.com"
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label" htmlFor="password">
                    Password
                  </label>
                  <div className="control">
                    <input className="input" type="password" name="password" />
                  </div>
                </div>

                <div className="userAgreement">
                  <span>
                    By creating an account, you agree to our{" "}
                    <a href="#" target="_blank">
                      Terms of Service
                    </a>{" "}
                    and{" "}
                    <a href="#" target="_blank">
                      Privacy & Cookie Statement
                    </a>
                    .
                  </span>
                </div>

                <button
                  className="button is-rounded is-black is-fullwidth"
                  type="submit"
                >
                  Create Account
                </button>
              </form>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
